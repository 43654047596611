<template>
  <section>
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <!--begin::Card-->
          <div class="card">
            <div class="card-body p-10 p-lg-15">
              <h1 class="anchor fw-bold mb-5" id="overview">Client credentials</h1>
              <div class="py-5">For read only, like autocomplete/search need only <strong>Client ID</strong>.<br>For write mode you need <strong>Client secret</strong> too for <a href="https://docs.upsearch.cz/" target="_new">obtain <strong>Access token</strong></a>.</div>

              <div class="mb-5 fv-row">
                <v-text-field
                    v-model="current.token"
                    label="Client ID"
                    readonly
                    filled
                ></v-text-field>
                <v-text-field
                    v-model="current.client_secret"
                    label="Client secret"
                    readonly
                    filled
                ></v-text-field>

              </div>
              <div class="d-flex justify-content-between border-top pt-10"></div>
              <p>Rest Api documentation you find on <a href="https://docs.upsearch.cz/#tag/Authentication/operation/07c4685b5352a5c58fdd81e50c017b14" target="_new">Upsearch REST API page</a>.</p>
            </div>
          </div>
          <!--end::Card-->
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import {
  PULL_CREDITALS,
  REFRESH,
} from "@/core/services/store/project.module";
import { RELOAD_MESSAGE } from "@/core/services/store/message.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data: () => ({
    valid: true,
    loading: {
      submit: false,
      delete: false
    }
  }),
  methods: {
    reloadData() {
      this.$store.dispatch(PULL_CREDITALS);
    }
  },
  watch: {
    changeProject() {
      this.reloadData();
    },
    changeCollection() {
      this.reloadData();
    }
  },
  computed: {
    changeProject() {
      return this.currentProject.token;
    },
    disabled() {
      return !(
          this.currentUserPersonalInfo.is_admin ||
          this.currentUserPersonalInfo.is_owner
      );
    },
    ...mapState({
      errors: state => state.project.errors,
      message: state => state.message.message,
      hasMessage: state => state.project.hasMessage,
      project_users: state => state.project.project_users,
      languages: state => state.project.languages,
      current: state => state.project.current_project
    }),
    ...mapGetters(["currentProject", "currentUserPersonalInfo"])
  },
  beforeCreate() {
    this.$store.dispatch(REFRESH);
    this.$store.dispatch(RELOAD_MESSAGE);
  },
  mounted() {
    this.reloadData();
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Credentials" }]);
  }
};
</script>
